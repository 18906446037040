import React, { useState } from "react";
import {
	Button,
	TextField,
	DialogContent,
	FormLabel,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ManualAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/ManualAccountsSelection";
import { CustomAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/CustomAccountsSelection";
import {
	CustomObjIncAccountFilters,
	CustomObjIncProductFilters,
	Item,
	MeasureState,
	MeasureSymbol,
	SemiItem,
	initialSubInc,
	isIncentive,
} from "../../types/commonObjIncTypes";
import { AddPrizesModal } from "./addPrizesModal/AddPrizesModal";
import {
	Incentive,
	IncentiveTypeEnum,
	IncentiveUser,
} from "../../types/incentivesTypes";
import { SubItemList } from "../itemsList/SubItemList";
import { DividerFormControl } from "../../common/DividerFormControl";
import { TargetComponent } from "./targetComponent/TargetComponent";
import { PayoutComponent } from "./payoutComponents/PayoutComponent";
import {
	GroupedTableItem,
	PayoutDetails,
	UserRole,
	defaultAdvancedPayments,
	getPayoutType,
	initializeMeasures,
	updatedUsers,
} from "./utils";
import { EditSubItemComponent } from "./EditSubItemComponent";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { AddNewSubItemComponent } from "../addObjectiveComponents/addNewSubItem";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import useItemValidation from "../../hooks/useItemValidation";
import useHandleIncentiveUpdate from "../../hooks/useHandleIncentiveUpdate";
import { ValueInput } from "./valueObjective/ValueInputComponent";
import { MultiPayoutComponent } from "./payoutComponents/MultiPayoutComponent";
import SelectionTabs from "./accountsProductsUsersSelect/SelectionTabs";
import { CustomProductsSelection } from "./accountsProductsUsersSelect/productsSelect/CustomProductsSelection";
import { FormProvider, useForm } from "react-hook-form";
import { CustomUsersSelection } from "./accountsProductsUsersSelect/usersSelect/CustomUsersSelection";
import ManualProductsSelection from "./accountsProductsUsersSelect/productsSelect/ManualProductsSelection";
import MinimumQuantityComponent from "./minimumQuantityComponent/MinimumQuantityComponent";
import useFilteredIncentives from "../../hooks/useFilteredIncentives";
import useGroupedIncentive from "../../hooks/useGroupedIncentives";
import { GroupedTable } from "./groupedObjective/GroupedTable";

interface EditIncentiveComponentProps {
	initialItemState: Incentive;
	onSave: (item: Item) => void;
	onClose: () => void;
}

export const EditIncentiveComponent: React.FC<EditIncentiveComponentProps> = ({
	initialItemState,
	onSave,
	onClose,
}) => {
	const classes = useStyles();
	const methods = useForm();

	const [item, setItem] = useState<Incentive>(initialItemState as Incentive);
	const [open, setOpen] = useState(false);
	const { addSubIncentive, deleteSubIncentive, editSubIncentive } =
		useHandleIncentiveUpdate();

	const {
		filteredIncentives,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
	} = useFilteredIncentives({ parentId: item._id });

	const {
		addGroupedIncentives,
		groupedIncentives,
		groupedTotalPages,
		loadingGrouped,
		setCurrentGroupedPage,
		currentGroupedPage,
	} = useGroupedIncentive({ itemId: item._id });

	const [selectionType, setSelectionType] = useState<boolean>(
		item.customAccountsSelected ?? true
	);

	const handleTabChange = (newValue: boolean) => {
		setSelectionType(newValue);
	};

	const [selectionTypeProd, setSelectionTypeProd] = useState<boolean>(
		item.customProductsSelected ?? true
	);
	const handleProdTabChange = (newValue: boolean) => {
		setSelectionTypeProd(newValue);
	};
	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		target: item?.target || 0,
		payoutType: item?.payoutType || "perAction",
		allOrNothingAmount: item?.quantityPayout || 0,
		perActionAmount: item?.quantityPayout || 0,
		atRiskAmount: item?.quantityPayout || 0,
		threshold: item?.threshold || { units: "percentage", value: 0 },
		cap: item?.cap || { units: "percentage", value: 100 },
		isOpenEnded: item.isOpenEnded,
		advancedBudget: item.advancedBudget || 0,
		advancedPayments: item.advancedPayments || defaultAdvancedPayments,
	});

	const [minQuantityValue, setMinQuantityValue] = useState(
		item.minQuantityValue || 0
	);

	const handleMinQuantityValueChange = (value: number) => {
		setMinQuantityValue(value);
		setItem((prev) => ({
			...prev,
			minQuantityValue: value,
		}));
	};

	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>({
			...{
				supplier: [],
				brand: [],
				packageType: [],
				size: [],
				productType: [],
				brandFams: [],
			},
			...Object.fromEntries(
				Object.entries(initialItemState.productsFilters || {}).filter(
					([key]) => key !== "premise"
				)
			),
			brandFams: initialItemState.productsFilters?.brandFams || [],
		});

	const [selectedAccountsFilters, setSelectedAccountsFilters] =
		useState<CustomObjIncAccountFilters>(
			initialItemState.accountsFilters || {
				accountType: [],
				premise: [],
				chain: [],
				location: [],
			}
		);

	const [selectedUsers, setSelectedUsers] = useState<UserRole[]>(
		item?.users
			? item?.users?.map((user) => ({
					user: user.user,
					isTeamLead: user.isTeamLead || false,
			  }))
			: []
	);

	const [measures, setMeasures] = useState(
		initializeMeasures(initialItemState.measure)
	);

	const [valueInputType, setValueInputType] = useState(item.valueInputType);
	const [valueInput, setValueInput] = useState(item.valueInput);

	const handleValueInputChange = (name: string, value: string | number) => {
		if (name === "valueInputType") {
			setValueInputType(value as string);
			setItem((prev) => ({
				...prev,
				valueInputType: value as string,
			}));
		} else if (name === "valueInput") {
			setValueInput(value as number);
			setItem((prev) => ({
				...prev,
				valueInput: value as number,
			}));
		}
	};

	const [selectedGroupedIncentives, setSelectedGroupedIncentives] =
		useState<GroupedTableItem[]>();

	const handleChangeGroupedIncentives = (
		updatedGroupedIncentives: Incentive[]
	) => {
		const formattedGroupedIncentives = updatedGroupedIncentives.map((inc) => ({
			id: inc._id,
			weight: inc.weight !== undefined ? inc.weight : 0,
		}));
		setSelectedGroupedIncentives(formattedGroupedIncentives);
	};

	const { isFormValid } = useItemValidation(item, item.subIncentives);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleMeasureChange = (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
							...measure,
							startDate,
							endDate,
							checked,
							symbol,
							trackingOption,
							trackingPeriodStart,
							trackingPeriodEnd,
							referenceTrackingPeriod,
					  }
					: { ...measure, checked: false }
			)
		);
	};

	const handleUserSelectionChange = (selectedUsers: UserRole[]) => {
		if (isIncentive(item)) {
			setSelectedUsers(selectedUsers);
			const advancedBudget =
				item.type === "grouped" ? payoutDetails.advancedBudget : undefined;
			const advancedPayments =
				item.type === "grouped" ? payoutDetails.advancedPayments : undefined;

			setItem((prev) => ({
				...prev,
				users: updatedUsers(
					selectedUsers,
					item,
					payoutDetails.target,
					getPayoutType(payoutDetails) || 0,
					payoutDetails.payoutType,
					item.isOpenEnded,
					payoutDetails.cap,
					payoutDetails.threshold,
					minQuantityValue,
					advancedBudget,
					advancedPayments
				) as IncentiveUser[],
			}));
		}
	};

	const handleChange = (name: string, value: string | number) => {
		setItem((prev) => {
			const updateIncentive = { ...prev, [name]: value };
			return updateIncentive;
		});
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};

	const [selectedSubIncentive, setSelectedSubIncentive] = useState<Incentive>();
	const [isEditSubIncentiveModalOpen, setIsEditSubIncentiveModalOpen] =
		useState(false);

	const [hideFromSalesReps, setHideFromSalesReps] = useState<boolean>(
		item.hideFromSalesReps || false
	);

	const handleHideFromSalesRepsChange = (checked: boolean) => {
		setItem((prevItem) => ({
			...prevItem,
			hideFromSalesReps: checked,
		}));
		setHideFromSalesReps(checked);
	};

	const handleEditSubIncentive = (subIncentiveId: string) => {
		if (!item?.subIncentives || !item.subIncentives) return;

		const subIncentive = item.subIncentives?.find(
			(it) => it._id === subIncentiveId
		);

		if (!subIncentive) return;

		setSelectedSubIncentive(subIncentive);
		setIsEditSubIncentiveModalOpen(true);
	};

	const handleDeleteSubIncentive = async (id: string, parentId: string) => {
		const updatedIncentive = await deleteSubIncentive(id, parentId);
		if (!updatedIncentive) return;
		setItem(updatedIncentive);
	};

	const handleSubmit = () => {
		if (!isFormValid()) {
			return;
		}

		const transformedUsers = selectedUsers.map((selectedUserId) => {
			// Find the user in the existing users array
			const existingUser = item?.users?.find(
				({ user }) => user === selectedUserId.user
			);

			if (existingUser) {
				return existingUser;
			}

			// If the user is new, return a new user object with default values
			return {
				user: selectedUserId.user,
				isTeamLead: selectedUserId.isTeamLead || false,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subIncentives: null,
				goal: null,
				target: payoutDetails.target,
				payout: getPayoutType(payoutDetails) || 0,
				payoutType: payoutDetails.payoutType,
				isOpenEnded: item.isOpenEnded,
				cap: payoutDetails.cap,
				threshold: payoutDetails.threshold,
				isEdited: false,
				minQuantityValue: minQuantityValue,
			};
		});

		let updatedIncentive = {
			...item,
			measure: getMeasuresForSave(measures),
			users: transformedUsers,
			subIncentives: item.subIncentives,
			payoutType: payoutDetails.payoutType,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			target: payoutDetails.target,
			payout: payoutDetails.target,
			valueInputType: valueInputType,
			valueInput: valueInput,
			isValid: true,
			productsFilters: selectedProductsFilters,
			accountsFilters: selectedAccountsFilters,
			hideFromSalesReps: hideFromSalesReps,
			customAccountsSelected: selectionType,
			customProductsSelected: selectionTypeProd,
		};

		if (item.type === IncentiveTypeEnum.Quantity) {
			updatedIncentive = {
				...updatedIncentive,
				measure: getMeasuresForSave(measures),
			};
		}
		if (item.type !== IncentiveTypeEnum.Multi) {
			updatedIncentive = {
				...updatedIncentive,
				quantityPayout: getPayoutType(payoutDetails) || 0,
			};
		}

		if (item.type === IncentiveTypeEnum.ImageValidation) {
			updatedIncentive = {
				...updatedIncentive,
				minQuantityValue: minQuantityValue,
			};
		}

		updatedIncentive = {
			...updatedIncentive,
			rankingPrizes: prizes,
		};

		if (item.type === IncentiveTypeEnum.Multi) {
			updatedIncentive.subIncentives?.forEach((subIncentive) => {
				const hasChanges =
					updatedIncentive.customPeriodStart !==
						subIncentive.customPeriodStart ||
					updatedIncentive.customPeriodEnd !== subIncentive.customPeriodEnd ||
					JSON.stringify(updatedIncentive.users) !==
						JSON.stringify(subIncentive.users);

				if (hasChanges) {
					subIncentive.customPeriodStart = updatedIncentive.customPeriodStart;
					subIncentive.customPeriodEnd = updatedIncentive.customPeriodEnd;
					updatedIncentive.users?.forEach((user) => {
						const existingUser = subIncentive.users?.find(
							({ user: subUser }) => subUser === user.user
						);
						if (!existingUser) {
							subIncentive.users = [
								...(subIncentive.users || []),
								{
									user: user.user,
									isTeamLead: user.isTeamLead || false,
									images: [],
									quantity: null,
									validation: null,
									completed: false,
									goal: null,
									target: subIncentive.target || 0,
									payout: subIncentive.quantityPayout || 0,
									payoutType: subIncentive.payoutType || "perAction",
									isOpenEnded: subIncentive.isOpenEnded,
									cap: subIncentive.cap || {
										units: "raw",
										value: 100,
									},
									threshold: subIncentive.threshold || {
										units: "raw",
										value: 100,
									},
									totalPods: 0,
									isEdited: false,
									minQuantityValue: subIncentive.minQuantityValue || 0,
								},
							];
						}
					});

					subIncentive.users = subIncentive.users?.filter(({ user: subUser }) =>
						updatedIncentive.users?.some(({ user }) => user === subUser)
					);
				}
				editSubIncentive(subIncentive._id, item._id, subIncentive, true);
			});
		}

		if (item.type === IncentiveTypeEnum.Grouped) {
			const incentivesToSend =
				selectedGroupedIncentives && selectedGroupedIncentives.length > 0
					? selectedGroupedIncentives
					: groupedIncentives.map((inc) => ({
							id: inc._id,
							weight: inc.weight !== undefined ? inc.weight : 0,
					  }));
			if (
				selectedGroupedIncentives?.length === 0 &&
				groupedIncentives.length > 0
			) {
				addGroupedIncentives([], item._id);
			} else {
				addGroupedIncentives(incentivesToSend, item._id);
			}
			updatedIncentive = {
				...updatedIncentive,
				advancedBudget: payoutDetails.advancedBudget,
				advancedPayments: payoutDetails.advancedPayments,
			};
		}
		onSave(updatedIncentive);
	};

	const [prizeModalOpen, setPrizeModalOpen] = useState(false);
	const [prizes, setPrizes] = useState(initialItemState?.rankingPrizes ?? []);

	const handlePrizeModalOpen = () => {
		setPrizeModalOpen(true);
	};

	const handlePrizeModalClose = () => {
		setPrizeModalOpen(false);
	};

	const handleTargetChange = (value: number) => {
		setPayoutDetails((prevItem) => ({
			...prevItem,
			target: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setItem((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
		}));

		setPayoutDetails((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
			payoutType: "perAction",
		}));
	};

	if (initialItemState === undefined) return null;

	const handleAddSubIncentive = async (
		newSubIncentiveData: SemiItem,
		parentIncentiveId: string
	) => {
		newSubIncentiveData.customPeriodStart = item.customPeriodStart;
		newSubIncentiveData.customPeriodEnd = item.customPeriodEnd;

		const subInc = await addSubIncentive(
			{ ...newSubIncentiveData, parentIncentiveId } as Omit<Incentive, "_id">,
			parentIncentiveId
		);

		if (!subInc) return;

		setItem((prev) => ({
			...prev,
			subIncentives: [...(prev?.subIncentives || []), subInc],
		}));
		setSelectedSubIncentive(subInc);
		setIsEditSubIncentiveModalOpen(true);
	};

	const handleDuplicateSubIncentive = (
		id: string,
		parentIncentiveId: string
	) => {
		const subIncentive = item.subIncentives?.find((it) => it._id === id);
		if (subIncentive) {
			const newName = `${subIncentive.name} copy`;
			const {
				_id: objId,
				customId,
				users: originalUsers,
				...restOfSubObject
			} = subIncentive;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
				minQuantityValue: 0,
			}));

			handleAddSubIncentive(
				{
					...restOfSubObject,
					name: newName,
					users: initializedUsers,
				},
				parentIncentiveId
			);
		}
	};

	const handleSaveSubIncentive = (editedSubIncentive: Incentive) => {
		const subIncentiveId = editedSubIncentive._id;

		const updatedSubIncentives = item.subIncentives?.map((subItem) =>
			subItem._id === editedSubIncentive._id ? editedSubIncentive : subItem
		);

		setItem((prev) => ({
			...prev,
			subIncentives: updatedSubIncentives,
		}));

		editSubIncentive(subIncentiveId, item._id, editedSubIncentive, false);

		setIsEditSubIncentiveModalOpen(false);
	};

	const handleMultiPayoutChange = (quantityPayout: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));

		setItem((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));
	};

	return (
		<FormProvider {...methods}>
			<div>
				<DialogContent className={classes.dialogContent}>
					<Typography
						align="center"
						variant="h6"
						className={classes.dialogTitle}
					>
						{capitalizeFirstLetter(item.type)} Incentive
					</Typography>
					<NameDescriptionPeriodComponent
						item={item}
						handleChange={handleChange}
					/>
					{item.type === IncentiveTypeEnum.Grouped && (
						<DividerFormControl>
							<GroupedTable
								itemType="incentive"
								filteredItems={filteredIncentives}
								groupedItems={groupedIncentives}
								onGroupedItemsChange={handleChangeGroupedIncentives}
								filteredData={{
									currentFilteredPage: currentPage,
									loadingFiltered: loading,
									totalFilteredPages: totalPages,
									setCurrentFilteredPage: setCurrentPage,
								}}
								groupedData={{
									groupedTotalPages,
									loadingGrouped,
									setCurrentGroupedPage,
									currentGroupedPage,
								}}
							/>
						</DividerFormControl>
					)}
					{item.type !== IncentiveTypeEnum.Multi && (
						<>
							<TargetComponent
								name={"Target"}
								target={payoutDetails?.target || 0}
								isOpenEnded={item.isOpenEnded}
								onTargetChange={handleTargetChange}
								onIsOpenEndedChange={handleIsOpenEndedChange}
								itemType={item.type}
							/>
						</>
					)}
					{item.type === IncentiveTypeEnum.ImageValidation && (
						<MinimumQuantityComponent
							minQuantityValue={minQuantityValue}
							onMinQuantityValueChange={handleMinQuantityValueChange}
						/>
					)}
					{item.type === IncentiveTypeEnum.Multi && (
						<>
							<MultiPayoutComponent
								subItems={item.subIncentives}
								onPayoutChange={handleMultiPayoutChange}
							/>
						</>
					)}
					{item.type !== IncentiveTypeEnum.Multi && (
						<>
							<Divider className={classes.divider} />
							<div className={classes.flexColumn}>
								<PayoutComponent
									payoutDetails={payoutDetails}
									setPayoutDetails={setPayoutDetails}
									itemType={item.type}
								/>
							</div>
						</>
					)}

					{item.type === IncentiveTypeEnum.Validation && (
						<DividerFormControl>
							<div className={classes.validationObjContainer}>
								<div>
									<ValueInput
										valueInputType={item.valueInputType}
										valueInput={item.valueInput}
										handleChange={handleValueInputChange}
									/>
								</div>
							</div>
						</DividerFormControl>
					)}

					<DividerFormControl>
						<FormLabel className={classes.spacedCenterAlign}>
							Supplier Contribution: %
						</FormLabel>
						<TextField
							margin="dense"
							id="supplierContribution"
							variant="outlined"
							type="number"
							fullWidth
							name="supplierContribution"
							value={item.supplierContribution}
							className={classes.flex2}
							onChange={(event) =>
								handleChange("supplierContribution", event.target.value)
							}
						/>
					</DividerFormControl>
					{item.type === IncentiveTypeEnum.Quantity && (
						<>
							<Divider className={classes.divider} />
							<QuantityForm
								measures={measures}
								handleMeasureChange={handleMeasureChange}
								customPeriodStart={item.customPeriodStart}
								customPeriodEnd={item.customPeriodEnd}
							/>
						</>
					)}
					<DividerFormControl>
						<div className={classes.accordionsContainer}>
							{item.type !== IncentiveTypeEnum.Multi &&
								item.type !== IncentiveTypeEnum.Grouped && (
									<>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1-content"
												id="panel1-header"
												className={classes.accordeonFontSize}
											>
												Accounts:
											</AccordionSummary>
											<AccordionDetails className={classes.flexColumn}>
												<SelectionTabs
													selectionType={selectionType}
													setSelectionType={handleTabChange}
												/>

												{selectionType && (
													<CustomAccountsSelection
														selectedAccountsFilters={selectedAccountsFilters}
														setSelectedAccountsFilters={
															setSelectedAccountsFilters
														}
													/>
												)}
												{!selectionType && (
													<ManualAccountsSelection item={initialItemState} />
												)}
											</AccordionDetails>
										</Accordion>

										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1-content"
												id="panel1-header"
												className={classes.accordeonFontSize}
											>
												Products:
											</AccordionSummary>
											<AccordionDetails className={classes.flexColumn}>
												<SelectionTabs
													selectionType={selectionTypeProd}
													setSelectionType={handleProdTabChange}
												/>
												{selectionTypeProd && (
													<CustomProductsSelection
														selectedProductsFilters={selectedProductsFilters}
														setSelectedProductsFilters={
															setSelectedProductsFilters
														}
													/>
												)}
												{!selectionTypeProd && (
													<ManualProductsSelection item={initialItemState} />
												)}
											</AccordionDetails>
										</Accordion>
									</>
								)}
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									className={classes.accordeonFontSize}
								>
									Users:
								</AccordionSummary>
								<AccordionDetails className={classes.flexColumn}>
									<CustomUsersSelection
										initialSelectedUserIds={selectedUsers}
										onChange={handleUserSelectionChange}
										hideFromSalesReps={hideFromSalesReps}
										onCheckChange={handleHideFromSalesRepsChange}
									/>
								</AccordionDetails>
							</Accordion>
						</div>
					</DividerFormControl>
					<div className={classes.prizesButtonWrapper}>
						<Button
							className={classes.button}
							color="primary"
							startIcon={<AddCircleOutlineIcon />}
							onClick={handlePrizeModalOpen}
						>
							Edit Prizes
						</Button>
					</div>
					{prizeModalOpen && prizes !== undefined && (
						<AddPrizesModal
							handleClose={handlePrizeModalClose}
							customPrizes={prizes}
							handlePrizesChange={(prizes) => setPrizes(prizes)}
						/>
					)}
					{item.type === IncentiveTypeEnum.Multi && (
						<>
							<div className={classes.buttonContainer}>
								<Button
									className={classes.button}
									color="primary"
									startIcon={<AddCircleOutlineIcon />}
									onClick={handleClickOpen}
								>
									New Sub Incentive
								</Button>
							</div>

							<AddNewSubItemComponent
								open={open}
								initState={initialSubInc}
								itemType="incentive"
								onSave={(item: SemiItem, parentItemId: string) => {
									handleAddSubIncentive(
										item as Omit<Incentive, "_id">,
										parentItemId
									);
								}}
								onClose={() => setOpen(false)}
								parentItemId={item._id}
							/>
							<SubItemList
								subItems={item.subIncentives as Incentive[]}
								onEdit={handleEditSubIncentive}
								onDelete={handleDeleteSubIncentive}
								onDuplicate={handleDuplicateSubIncentive}
							/>
						</>
					)}

					{isEditSubIncentiveModalOpen && selectedSubIncentive && (
						<EditSubItemComponent
							initialSubItem={selectedSubIncentive as Incentive}
							open={true}
							onSave={handleSaveSubIncentive}
							onClose={() => setIsEditSubIncentiveModalOpen(false)}
							itemType={"incentive"}
							parentUsers={item.users}
						/>
					)}
				</DialogContent>
				<div className={classes.buttonsWrapper}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						type="submit"
						onClick={methods.handleSubmit(handleSubmit)}
						color="primary"
					>
						Save
					</Button>
				</div>
			</div>
		</FormProvider>
	);
};
