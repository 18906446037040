import { FormControl, IconButton } from "@material-ui/core";
import Select from "react-select";
import useProductsFilters from "../../../../hooks/useProductsFilters";
import { CustomObjIncProductFilters } from "../../../../types/commonObjIncTypes";
import { useStyles } from "./ProductsSelection.styles";
import { AddCircleOutline } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

type CustomProductsSelectionProps = {
	selectedProductsFilters: CustomObjIncProductFilters;
	setSelectedProductsFilters: React.Dispatch<
		React.SetStateAction<CustomObjIncProductFilters>
	>;
};

export const CustomProductsSelection: React.FC<
	CustomProductsSelectionProps
> = ({ selectedProductsFilters, setSelectedProductsFilters }) => {
	const classes = useStyles();
	const {
		suppliers,
		brands,
		packageTypes,
		sizes,
		productTypes,
		brandFams,
	} = useProductsFilters();

	const getOptionsForFilter = (filterName: string) => {
		switch (filterName) {
			case "supplier":
				return suppliers.map((supplier) => ({
					value: supplier,
					label: supplier,
				}));
			// case 'noSupplier':
			//     return suppliers.map(supplier => ({ value: supplier, label: supplier }));
			case "brand":
				return brands.map((brand) => ({ value: brand, label: brand }));
			// case 'noBrand':
			//     return brands.map(brand => ({ value: brand, label: brand }));
			case "packageType":
				return packageTypes.map((packageType) => ({
					value: packageType,
					label: packageType,
				}));
			case "size":
				return sizes.map((size) => ({ value: size, label: size }));
			case "productType":
				return productTypes.map((productType) => ({
					value: productType,
					label: productType,
				}));
			case "brandFams":
				return brandFams?.map((bf) => ({ value: bf, label: bf }));
			default:
				return [];
		}
	};

	const handleProductSelectChange = (
		selectedOptions: any,
		selectName: string
	) => {
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[selectName]: selectedOptions
				? selectedOptions.map((option: any) => option.value)
				: [],
		}));
	};

	const handleSelectAll = (filterName: string) => {
		const allOptions = getOptionsForFilter(filterName).map(
			(option) => option.value
		);
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[filterName]: allOptions,
		}));
	};

	const handleRemoveAll = (filterName: string) => {
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[filterName]: [],
		}));
	};

	const filterLabels: { [key: string]: string } = {
		supplier: "Suppliers",
		// noSupplier: 'Excluded Suppliers',
		brand: "Brands",
		// noBrand: 'Excluded Brands',
		packageType: "Package Types",
		size: "Sizes",
		productType: "Product Types",
		brandFams: "Brand Family",
	};

	return (
		<div className={classes.container}>
			{Object.entries(selectedProductsFilters).map(
				([filterName, selectedValues]) => (
					<FormControl
						key={filterName}
						variant="outlined"
						className={classes.marginTop}
					>
						<div className={classes.rowContainer}>
							<Select
								className={classes.select}
								isMulti
								name={filterName}
								placeholder={`Select ${filterLabels[filterName]}`}
								options={getOptionsForFilter(
									filterName as keyof CustomObjIncProductFilters
								)}
								value={
									Array.isArray(selectedValues)
										? selectedValues.map((value: string) => ({
												value,
												label: value,
										  }))
										: []
								}
								onChange={(selectedOption: any) =>
									handleProductSelectChange(
										selectedOption,
										filterName as keyof CustomObjIncProductFilters
									)
								}
								styles={{
									valueContainer: (provided) => ({
										...provided,
										maxHeight: "100px",
										overflowY: "auto",
									}),
								}}
							/>
							<div className={classes.buttonContainer}>
								<IconButton
									color="primary"
									size="small"
									onClick={() => handleSelectAll(filterName)}
								>
									<AddCircleOutline />
								</IconButton>
								<IconButton
									size="small"
									onClick={() => handleRemoveAll(filterName)}
								>
									<HighlightOffIcon />
								</IconButton>
							</div>
						</div>
					</FormControl>
				)
			)}
		</div>
	);
};
