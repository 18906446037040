import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	DialogContent,
	Divider,
	Typography,
} from "@material-ui/core";
import {
	MeasureState,
	Objective,
	ObjectiveTypeEnum,
	ObjectiveUser,
} from "../../types/objectiveTypes";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import { SubItemList } from "../itemsList/SubItemList";
import { EditSubItemComponent } from "./EditSubItemComponent";
import { AddNewSubItemComponent } from "../addObjectiveComponents/addNewSubItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
	CustomObjIncAccountFilters,
	CustomObjIncProductFilters,
	Item,
	MeasureSymbol,
	SemiItem,
	initialSubObj,
} from "../../types/commonObjIncTypes";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { DividerFormControl } from "../../common/DividerFormControl";
import { TargetComponent } from "./targetComponent/TargetComponent";
import { PayoutComponent } from "./payoutComponents/PayoutComponent";
import {
	GroupedTableItem,
	PayoutDetails,
	UserRole,
	defaultAdvancedPayments,
	getPayoutType,
	initializeMeasures,
	updatedUsers,
} from "./utils";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import useItemValidation from "../../hooks/useItemValidation";
import useHandleObjectiveUpdate from "../../hooks/useHandleObjectiveUpdate";
import { GroupedTable } from "./groupedObjective/GroupedTable";
import { ValueInput } from "./valueObjective/ValueInputComponent";
import { ManualAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/ManualAccountsSelection";
import { CustomAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/CustomAccountsSelection";
import { MultiPayoutComponent } from "./payoutComponents/MultiPayoutComponent";
import SelectionTabs from "./accountsProductsUsersSelect/SelectionTabs";
import { CustomProductsSelection } from "./accountsProductsUsersSelect/productsSelect/CustomProductsSelection";
import { FormProvider, useForm } from "react-hook-form";
import { CustomUsersSelection } from "./accountsProductsUsersSelect/usersSelect/CustomUsersSelection";
import ManualProductsSelection from "./accountsProductsUsersSelect/productsSelect/ManualProductsSelection";
import MinimumQuantityComponent from "./minimumQuantityComponent/MinimumQuantityComponent";
import useFilteredObjectives from "../../hooks/useFilteredObjectives";
import useGroupedObjective from "../../hooks/useGroupedObjectives";

interface EditObjectiveComponentProps {
	initialItemState: Item;
	onSave: (item: Item) => void;
	onClose: () => void;
}

const EditObjectiveComponent: React.FC<EditObjectiveComponentProps> = ({
	initialItemState,
	onSave,
	onClose,
}) => {
	const classes = useStyles();
	const methods = useForm();

	const [objective, setObjective] = useState<Objective>(
		initialItemState as Objective
	);

	const {
		filteredObjectives,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
	} = useFilteredObjectives({ parentId: objective._id });

	const {
		addGroupedObjectives,
		groupedObjectives,
		groupedTotalPages,
		loadingGrouped,
		setCurrentGroupedPage,
		currentGroupedPage,
	} = useGroupedObjective({ itemId: objective._id });

	const [selectedGroupedObjectives, setSelectedGroupedObjectives] =
		useState<GroupedTableItem[]>();

	const [selectionType, setSelectionType] = useState<boolean>(
		objective.customAccountsSelected ?? true
	);

	const handleTabChange = (newValue: boolean) => {
		setSelectionType(newValue);
	};

	const [selectionTypeProd, setSelectionTypeProd] = useState<boolean>(
		objective.customProductsSelected ?? true
	);
	const handleProdTabChange = (newValue: boolean) => {
		setSelectionTypeProd(newValue);
	};
	const { addSubObjective, deleteSubObjective, editSubObjective } =
		useHandleObjectiveUpdate();

	const handleChangeGroupedObjectives = (
		updatedGroupedObjectives: Objective[]
	) => {
		const formattedGroupedObjectives = updatedGroupedObjectives.map((obj) => ({
			id: obj._id,
			weight: obj.weight !== undefined ? obj.weight : 0,
		}));
		setSelectedGroupedObjectives(formattedGroupedObjectives);
	};

	const [open, setOpen] = useState(false);

	const [minQuantityValue, setMinQuantityValue] = useState(
		objective.minQuantityValue || 0
	);

	const handleMinQuantityValueChange = (value: number) => {
		setMinQuantityValue(value);
		setObjective((prev) => ({
			...prev,
			minQuantityValue: value,
		}));
	};

	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		target: objective?.target || 0,
		payoutType: objective?.payoutType || "perAction",
		allOrNothingAmount: objective?.quantityPayout || 0,
		perActionAmount: objective?.quantityPayout || 0,
		atRiskAmount: objective?.quantityPayout || 0,
		threshold: objective?.threshold || { units: "percentage", value: 0 },
		cap: objective?.cap || { units: "percentage", value: 100 },
		isOpenEnded: objective.isOpenEnded,
		advancedBudget: objective.advancedBudget || 0,
		advancedPayments: objective.advancedPayments || defaultAdvancedPayments,
	});
	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>({
			...{
				supplier: [],
				brand: [],
				packageType: [],
				size: [],
				productType: [],
				brandFams: [],
			},
			...Object.fromEntries(
				Object.entries(initialItemState.productsFilters || {}).filter(
					([key]) => key !== "premise"
				)
			),
			brandFams: initialItemState.productsFilters?.brandFams || [],
		});
	const [selectedAccountsFilters, setSelectedAccountsFilters] =
		useState<CustomObjIncAccountFilters>(
			initialItemState.accountsFilters || {
				accountType: [],
				premise: [],
				chain: [],
				location: [],
			}
		);

	const [measures, setMeasures] = useState(
		initializeMeasures(initialItemState.measure)
	);
	const [valueInputType, setValueInputType] = useState(
		objective.valueInputType || "gt"
	);
	const [valueInput, setValueInput] = useState(objective.valueInput);

	const handleValueInputChange = (name: string, value: string | number) => {
		if (name === "valueInputType") {
			setValueInputType(value as string);
			setObjective((prev) => ({
				...prev,
				valueInputType: value as string,
			}));
		} else if (name === "valueInput") {
			setValueInput(value as number);
			setObjective((prev) => ({
				...prev,
				valueInput: value as number,
			}));
		}
	};

	// const [advancedPayments, setAdvancedPayments] = useState<AdvancedPayment[]>([
	// 	{ completed: 0, ofBudget: 0 },
	// ]);

	const { isFormValid } = useItemValidation(objective, objective.subObjectives);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const [selectedUsers, setSelectedUsers] = useState<UserRole[]>(
		objective?.users
			? objective?.users?.map((user) => ({
					user: user.user,
					isTeamLead: user.isTeamLead || false,
			  }))
			: []
	);
	const [selectedSubObjective, setSelectedSubObjective] = useState<Objective>();
	const [isEditSubObjectiveModalOpen, setIsEditSubObjectiveModalOpen] =
		useState(false);

	const [hideFromSalesReps, setHideFromSalesReps] = useState<boolean>(
		objective.hideFromSalesReps || false
	);

	const handleHideFromSalesRepsChange = (checked: boolean) => {
		setObjective((prevObjective) => ({
			...prevObjective,
			hideFromSalesReps: checked,
		}));
		setHideFromSalesReps(checked);
	};

	const handleMeasureChange = (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
							...measure,
							startDate,
							endDate,
							checked,
							symbol,
							trackingOption,
							trackingPeriodStart,
							trackingPeriodEnd,
							referenceTrackingPeriod,
					  }
					: { ...measure, checked: false }
			)
		);
	};

	const handleUserSelectionChange = (selectedUsers: UserRole[]) => {
		setSelectedUsers(selectedUsers);

		const advancedBudget =
			objective.type === "grouped" ? payoutDetails.advancedBudget : 0;
		const advancedPayments =
			objective.type === "grouped" ? payoutDetails.advancedPayments : [];

		setObjective((prev) => ({
			...prev,
			users: updatedUsers(
				selectedUsers,
				objective,
				payoutDetails.target,
				getPayoutType(payoutDetails) || 0,
				payoutDetails.payoutType,
				objective.isOpenEnded,
				payoutDetails.cap,
				payoutDetails.threshold,
				minQuantityValue,
				advancedBudget,
				advancedPayments
			) as ObjectiveUser[],
		}));
	};

	const handleChange = (name: string, value: string | number) => {
		setObjective((prev) => {
			const updatedObjective = { ...prev, [name]: value };
			return updatedObjective;
		});
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};

	const handleEditSubObjective = (subObjectiveId: string) => {
		if (!objective?.subObjectives) return;

		const subObjective = objective.subObjectives?.find(
			(it) => it._id === subObjectiveId
		);
		if (!subObjective) return;

		setSelectedSubObjective(subObjective);
		setIsEditSubObjectiveModalOpen(true); // Open the modal
	};

	const handleAddSubObjective = async (
		newSubObjectiveData: SemiItem,
		parentObjectiveId: string
	) => {
		newSubObjectiveData.customPeriodStart = objective.customPeriodStart;
		newSubObjectiveData.customPeriodEnd = objective.customPeriodEnd;

		const subObj = await addSubObjective(
			{ ...newSubObjectiveData, parentObjectiveId } as Omit<Objective, "_id">,
			parentObjectiveId
		);

		setObjective((prev) => {
			const subObjectives = prev.subObjectives || [];
			const exists = subObjectives.some((sub) => sub._id === subObj._id);

			if (exists) {
				return prev;
			}

			return {
				...prev,
				subObjectives: [...subObjectives, subObj],
			};
		});

		setSelectedSubObjective(subObj);
		setIsEditSubObjectiveModalOpen(true);
	};

	const handleDuplicateSubObjective = (
		id: string,
		parentObjectiveId: string
	) => {
		const subObjective = objective.subObjectives?.find((it) => it._id === id);
		if (subObjective) {
			const newName = `${subObjective.name} copy`;
			const {
				_id: objId,
				customId,
				users: originalUsers,
				...restOfSubObject
			} = subObjective;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
				minQuantityValue: 0,
				advancedBudget: 0,
				advancedPayments: [],
			}));

			handleAddSubObjective(
				{
					...restOfSubObject,
					name: newName,
					users: initializedUsers,
				},
				parentObjectiveId
			);
		}
	};

	const handleDeleteSubObjective = async (id: string, parentId: string) => {
		const updatedObjective = await deleteSubObjective(id, parentId);

		if (updatedObjective) {
			setObjective(updatedObjective);
		}
	};

	const handleObjectiveSubmit = () => {
		if (!isFormValid()) {
			return;
		}

		const transformedUsers = selectedUsers.map((selectedUserId) => {
			// Find the user in the existing users array
			const existingUser = objective?.users?.find(
				({ user }) => user === selectedUserId.user
			);

			if (existingUser) {
				return existingUser;
			}

			// If the user is new, return a new user object with default values
			return {
				user: selectedUserId.user,
				isTeamLead: selectedUserId.isTeamLead || false,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				target: payoutDetails.target,
				payout: getPayoutType(payoutDetails) || 0,
				payoutType: payoutDetails.payoutType,
				isOpenEnded: objective.isOpenEnded,
				cap: payoutDetails.cap,
				threshold: payoutDetails.threshold,
				isEdited: false,
				minQuantityValue: minQuantityValue,
			};
		});

		let updatedObjective: Objective = {
			...objective,
			users: transformedUsers,
			payoutType: payoutDetails.payoutType,
			subObjectives: objective.subObjectives,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			target: payoutDetails.target,
			payout: payoutDetails.target,
			valueInputType: valueInputType,
			valueInput: valueInput,
			isValid: true,
			productsFilters: selectedProductsFilters,
			accountsFilters: selectedAccountsFilters,
			hideFromSalesReps: hideFromSalesReps,
			customAccountsSelected: selectionType,
			customProductsSelected: selectionTypeProd,
		};

		if (objective.type === ObjectiveTypeEnum.Quantity) {
			updatedObjective = {
				...updatedObjective,
				measure: getMeasuresForSave(measures),
			};
		}
		if (objective.type !== ObjectiveTypeEnum.Multi) {
			updatedObjective = {
				...updatedObjective,
				quantityPayout: getPayoutType(payoutDetails) || 0,
			};
		}

		if (objective.type === ObjectiveTypeEnum.ImageValidation) {
			updatedObjective = {
				...updatedObjective,
				minQuantityValue: minQuantityValue,
			};
		}

		if (objective.type === ObjectiveTypeEnum.Multi) {
			updatedObjective.subObjectives?.forEach((subObjective) => {
				const hasChanges =
					updatedObjective.customPeriodStart !==
						subObjective.customPeriodStart ||
					updatedObjective.customPeriodEnd !== subObjective.customPeriodEnd ||
					JSON.stringify(updatedObjective.users) !==
						JSON.stringify(subObjective.users);

				if (hasChanges) {
					subObjective.customPeriodStart = updatedObjective.customPeriodStart;
					subObjective.customPeriodEnd = updatedObjective.customPeriodEnd;
					updatedObjective.users?.forEach((user) => {
						const existingUser = subObjective.users?.find(
							({ user: subUser }) => subUser === user.user
						);
						if (!existingUser) {
							subObjective.users = [
								...(subObjective.users || []),
								{
									user: user.user,
									images: [],
									quantity: null,
									validation: null,
									completed: false,
									goal: null,
									target: subObjective.target || 0,
									payout: subObjective.quantityPayout || 0,
									payoutType: subObjective.payoutType || "perAction",
									isOpenEnded: subObjective.isOpenEnded,
									cap: subObjective.cap || { units: "percentage", value: 100 },
									threshold: subObjective.threshold || {
										units: "percentage",
										value: 0,
									},
									isEdited: false,
									minQuantityValue: subObjective.minQuantityValue || 0,
								},
							];
						}
					});

					subObjective.users = subObjective.users?.filter(({ user: subUser }) =>
						updatedObjective.users?.some(({ user }) => user === subUser)
					);
				}
				editSubObjective(subObjective._id, objective._id, subObjective, true);
			});
		}
		if (objective.type === ObjectiveTypeEnum.Grouped) {
			const objectivesToSend =
				selectedGroupedObjectives && selectedGroupedObjectives.length > 0
					? selectedGroupedObjectives
					: groupedObjectives.map((obj) => ({
							id: obj._id,
							weight: obj.weight !== undefined ? obj.weight : 0,
					  }));
			if (
				selectedGroupedObjectives?.length === 0 &&
				groupedObjectives.length > 0
			) {
				addGroupedObjectives([], objective._id);
			} else {
				addGroupedObjectives(objectivesToSend, objective._id);
			}
			updatedObjective = {
				...updatedObjective,
				advancedBudget: payoutDetails.advancedBudget,
				advancedPayments: payoutDetails.advancedPayments,
			};
		}

		onSave(updatedObjective);
	};

	const handleSaveSubObjective = (editedSubObjective: Objective) => {
		const subObjectiveId = editedSubObjective._id;

		const updatedSubObjectives = objective.subObjectives?.map((subObj) =>
			subObj._id === editedSubObjective._id ? editedSubObjective : subObj
		);

		setObjective((prevObjective) => ({
			...prevObjective,
			subObjectives: updatedSubObjectives,
		}));

		editSubObjective(subObjectiveId, objective._id, editedSubObjective, false);

		setIsEditSubObjectiveModalOpen(false);
	};

	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			target: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setObjective((prevObjective) => ({
			...prevObjective,
			isOpenEnded: checked,
		}));
		setPayoutDetails((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
			payoutType: "perAction",
		}));
	};

	const handleMultiPayoutChange = (quantityPayout: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));

		setObjective((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));
	};
	if (initialItemState === undefined) return null;

	return (
		<FormProvider {...methods}>
			<div className={classes.modalHeight}>
				<DialogContent className={classes.dialogContent}>
					<Typography
						align="center"
						variant="h6"
						className={classes.dialogTitle}
					>
						{capitalizeFirstLetter(objective.type)} Objective
					</Typography>
					<NameDescriptionPeriodComponent
						item={objective}
						handleChange={handleChange}
					/>
					{objective.type === ObjectiveTypeEnum.Grouped && (
						<DividerFormControl>
							<GroupedTable
								itemType={"objective"}
								filteredItems={filteredObjectives}
								groupedItems={groupedObjectives}
								onGroupedItemsChange={handleChangeGroupedObjectives}
								filteredData={{
									currentFilteredPage: currentPage,
									loadingFiltered: loading,
									totalFilteredPages: totalPages,
									setCurrentFilteredPage: setCurrentPage,
								}}
								groupedData={{
									groupedTotalPages,
									loadingGrouped,
									setCurrentGroupedPage,
									currentGroupedPage,
								}}
							/>
						</DividerFormControl>
					)}
					{objective.type !== ObjectiveTypeEnum.Multi && (
						<>
							<TargetComponent
								name={"Target"}
								target={payoutDetails?.target || 0}
								isOpenEnded={objective.isOpenEnded}
								onTargetChange={handleTargetChange}
								onIsOpenEndedChange={handleIsOpenEndedChange}
								itemType={objective.type}
							/>
						</>
					)}
					{objective.type === ObjectiveTypeEnum.ImageValidation && (
						<MinimumQuantityComponent
							minQuantityValue={minQuantityValue}
							onMinQuantityValueChange={handleMinQuantityValueChange}
						/>
					)}
					{objective.type === ObjectiveTypeEnum.Multi && (
						<>
							<MultiPayoutComponent
								subItems={objective.subObjectives}
								onPayoutChange={handleMultiPayoutChange}
							/>
						</>
					)}
					{objective.type === ObjectiveTypeEnum.Quantity && (
						<>
							<Divider className={classes.divider} />
							<QuantityForm
								measures={measures}
								handleMeasureChange={handleMeasureChange}
								customPeriodStart={objective.customPeriodStart}
								customPeriodEnd={objective.customPeriodEnd}
							/>
						</>
					)}
					{objective.type !== ObjectiveTypeEnum.Multi && (
						<>
							<Divider className={classes.divider} />
							<div className={classes.flexColumn}>
								<PayoutComponent
									payoutDetails={payoutDetails}
									setPayoutDetails={setPayoutDetails}
									itemType={objective.type}
								/>
							</div>
						</>
					)}

					{objective.type === ObjectiveTypeEnum.Validation && (
						<DividerFormControl>
							<div className={classes.validationObjContainer}>
								<div>
									<ValueInput
										valueInputType={objective.valueInputType}
										valueInput={objective.valueInput}
										handleChange={handleValueInputChange}
									/>
								</div>
							</div>
						</DividerFormControl>
					)}
					<DividerFormControl>
						<div className={classes.accordionsContainer}>
							{objective.type !== ObjectiveTypeEnum.Multi &&
								objective.type !== ObjectiveTypeEnum.Grouped && (
									<>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1-content"
												id="panel1-header"
												className={classes.accordeonFontSize}
											>
												Accounts:
											</AccordionSummary>
											<AccordionDetails className={classes.flexColumn}>
												<SelectionTabs
													selectionType={selectionType}
													setSelectionType={handleTabChange}
												/>

												{selectionType && (
													<CustomAccountsSelection
														selectedAccountsFilters={selectedAccountsFilters}
														setSelectedAccountsFilters={
															setSelectedAccountsFilters
														}
													/>
												)}
												{!selectionType && (
													<ManualAccountsSelection item={initialItemState} />
												)}
											</AccordionDetails>
										</Accordion>

										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1-content"
												id="panel1-header"
												className={classes.accordeonFontSize}
											>
												Products:
											</AccordionSummary>
											<AccordionDetails className={classes.flexColumn}>
												<SelectionTabs
													selectionType={selectionTypeProd}
													setSelectionType={handleProdTabChange}
												/>
												{selectionTypeProd && (
													<CustomProductsSelection
														selectedProductsFilters={selectedProductsFilters}
														setSelectedProductsFilters={
															setSelectedProductsFilters
														}
													/>
												)}
												{!selectionTypeProd && (
													<ManualProductsSelection item={initialItemState} />
												)}
											</AccordionDetails>
										</Accordion>
									</>
								)}
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									className={classes.accordeonFontSize}
								>
									Users:
								</AccordionSummary>
								<AccordionDetails className={classes.flexColumn}>
									<CustomUsersSelection
										initialSelectedUserIds={selectedUsers}
										onChange={handleUserSelectionChange}
										hideFromSalesReps={hideFromSalesReps}
										onCheckChange={handleHideFromSalesRepsChange}
									/>
								</AccordionDetails>
							</Accordion>
						</div>
					</DividerFormControl>
					{objective.type === "multi" && (
						<>
							<div className={classes.buttonContainer}>
								<Button
									className={classes.button}
									color="primary"
									startIcon={<AddCircleOutlineIcon />}
									onClick={handleClickOpen}
								>
									New Sub-Objective
								</Button>
							</div>

							<AddNewSubItemComponent
								open={open}
								initState={{ ...initialSubObj }}
								itemType="objective"
								onSave={(item: SemiItem, parentItemId: string) =>
									handleAddSubObjective(
										item as Omit<Objective, "_id">,
										parentItemId
									)
								}
								onClose={() => setOpen(false)}
								parentItemId={objective._id}
							/>
							<SubItemList
								subItems={objective.subObjectives as Objective[]}
								onEdit={handleEditSubObjective}
								onDuplicate={handleDuplicateSubObjective}
								onDelete={handleDeleteSubObjective}
							/>
						</>
					)}
					{isEditSubObjectiveModalOpen && selectedSubObjective && (
						<EditSubItemComponent
							initialSubItem={selectedSubObjective as Objective}
							open={true}
							onSave={handleSaveSubObjective}
							onClose={() => setIsEditSubObjectiveModalOpen(false)}
							itemType={"objective"}
							parentUsers={objective.users}
						/>
					)}
				</DialogContent>
				<div className={classes.buttonsWrapper}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						type="submit"
						onClick={methods.handleSubmit(handleObjectiveSubmit)}
						color="primary"
					>
						Save
					</Button>
				</div>
			</div>
		</FormProvider>
	);
};

export default EditObjectiveComponent;
