import { useEffect, useState } from "react";
import { Button, ButtonGroup, Dialog, TextField, Typography } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { ItemCard } from "../../utils/ItemCard";
import { useStyles } from "../GroupedManagementSummary.styles";
import useReportGroupedIncentives from "./useReportGroupedIncentives";
import { Incentive, IncentiveUser } from "../../../usersManagement/types/incentivesTypes";
import useUsers from "../../../usersManagement/hooks/useUsers";
import { incentivesStoreZustand } from "../../store/incentivesStore";
import { GroupedReportTable } from "../GroupedReportTable";
import Pagination from "@material-ui/lab/Pagination";
import { initialPayoutDetails } from "../../utils/utils";
import { getPayoutType, PayoutDetails } from "../../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { IRow } from "../../Item/ItemManagementReportTable";
import useUserUpdate from "../../Item/useUserUpdate";
import { EditUserTargetPayout } from "../../utils/EditUserTargetPayout";

interface GroupedIncentivesManagementSummaryProps {
    showIncentives: boolean;
    setShowIncentives: (value: boolean) => void;
}
export const GroupedIncentivesManagementSummary = ({ showIncentives, setShowIncentives }: GroupedIncentivesManagementSummaryProps) => {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedIncentive, setSelectedIncentive] = useState<Incentive | null>(null);
    const [editRow, setEditRow] = useState<IRow>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [payoutDetails, setPayoutDetails] =
        useState<PayoutDetails>(initialPayoutDetails);
    const [username, setUsername] = useState<string | undefined>("");
    const [minQuantityValue, setMinQuantityValue] = useState<number | undefined>(0)

    const { rows, setRows } = incentivesStoreZustand();

    const [innerLoading, setInnerLoading] = useState(false);
    const {
        incentives,
        reportingGroupedPage,
        setReportingGroupedPage,
        totalPages,
        loading,
    } = useReportGroupedIncentives({
        searchPhrase: searchTerm !== "" ? searchTerm : undefined,
    });
    const { users } = useUsers();
    const { updateUserDetails } = useUserUpdate({ filterType: "grouped" });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };

    const handleItemSelect = (incentive: Incentive) => {
        const isSelected = selectedIncentive?._id === incentive._id;
        setSelectedIncentive(isSelected ? null : incentive);
        handleIncentiveSelect(isSelected ? null : incentive);
    };

    const handleIncentiveSelect = (selectedIncentive: Incentive | null) => {
        setInnerLoading(true);

        if (!selectedIncentive) {
            setRows([]);
            setInnerLoading(false);
            return;
        }

        let currentRows: any[] = [];

        const processIncentive = (incentive: Incentive, callback: any) => {
            const parentUsers = incentive.users ? incentive.users.map(user => user.user) : [];

            const allIncentives = incentive.groupedIncentives
                ? [...incentive.groupedIncentives]
                : [incentive];

            if (incentive.users) {
                incentive.users.forEach((parentUser: IncentiveUser) => {
                    const currUser = users.find((u) => u.customId === parentUser.user);
                    currentRows.push({
                        ...parentUser,
                        userName: currUser?.name,
                        route: currUser?.routeNumber.join(", "),
                        userType: currUser?.role,
                        incentive: incentive.name,
                        groupedIncentive: incentive.name,
                        startDate: incentive.customPeriodStart,
                        endDate: incentive.customPeriodEnd,
                        type: incentive.type,
                        quantity: parentUser.totalItems,
                        groupedQuantity: parentUser.totalItems,
                        status: parentUser.percentageAchieved || 0,
                        target: parentUser.isOpenEnded
                            ? "∞"
                            : parentUser.target === 0
                                ? incentive.isOpenEnded
                                    ? "∞"
                                    : incentive.target
                                : parentUser.target,
                        minQuantityValue: parentUser.minQuantityValue,
                        payout: parentUser.totalPayout,
                        payoutMTD: parentUser.totalAchieved,
                        image: parentUser.images?.filter(image => !image.rejected),
                        rejectedImages: parentUser.images?.filter(image => image.rejected),
                        products: incentive.products,
                        valueInput: incentive.valueInput,
                        valueInputType: incentive.valueInputType,
                        itemId: incentive._id,
                        userId: parentUser.user,
                        isObjective: false,
                        weight: incentive.weight,
                        individualStatus: parentUser.percentageAchieved,
                        groupedStatus: parentUser.percentageAchieved
                    });
                });
            }

            allIncentives.forEach((currentIncentive) => {
                if (currentIncentive.users) {
                    currentIncentive.users.forEach((user: IncentiveUser) => {
                        if (parentUsers.includes(user.user)) {
                            const currUser = users.find((u) => u.customId === user.user);
                            const parentUser = incentive.users?.find(parentUser => parentUser.user === user.user);
                            currentRows.push({
                                ...user,
                                userName: currUser?.name,
                                route: currUser?.routeNumber.join(", "),
                                userType: currUser?.role,
                                incentive: currentIncentive === incentive ? "-" : currentIncentive.name,
                                groupedIncentive: incentive.name,
                                startDate: currentIncentive.customPeriodStart,
                                endDate: currentIncentive.customPeriodEnd,
                                type: currentIncentive.type,
                                quantity: user.totalItems,
                                groupedQuantity: parentUser?.totalItems,
                                status: user.percentageAchieved || 0,
                                target: parentUser?.isOpenEnded
                                    ? "∞"
                                    : parentUser?.target === 0
                                        ? incentive.isOpenEnded
                                            ? "∞"
                                            : incentive.target
                                        : parentUser?.target,
                                minQuantityValue: user.minQuantityValue,
                                payout: parentUser?.totalPayout,
                                payoutMTD: parentUser?.totalAchieved,
                                image: user.images?.filter(image => !image.rejected),
                                rejectedImages: user.images?.filter(image => image.rejected),
                                products: currentIncentive.products,
                                valueInput: currentIncentive.valueInput,
                                valueInputType: currentIncentive.valueInputType,
                                itemId: currentIncentive._id,
                                userId: user.user,
                                isObjective: false,
                                weight: currentIncentive.weight,
                                individualStatus: user.percentageAchieved,
                                groupedStatus: parentUser?.percentageAchieved
                            });
                        }
                    });
                }
            });

            if (callback) callback();
        };


        processIncentive(selectedIncentive, () => {
            setRows(currentRows);
            setInnerLoading(false);
        });
    };

    const handleEditClick = (row: IRow) => {
        setEditRow(row);
        setUsername(row.userName);
        let selectedIncentive = incentives.find((inc) => inc._id === row.itemId);

        if (selectedIncentive && selectedIncentive.users) {
            const selectedUser = selectedIncentive.users.find(
                (user) => user.user === row.userId
            );
            if (selectedUser) {
                setPayoutDetails({
                    target: selectedUser.target,
                    payoutType: selectedUser.payoutType,
                    threshold: selectedUser.threshold,
                    cap: selectedUser.cap,
                    isOpenEnded: selectedUser.isOpenEnded,
                    allOrNothingAmount: selectedUser.payout,
                    perActionAmount: selectedUser.payout,
                    atRiskAmount: selectedUser.payout,
                    advancedBudget: selectedUser.advancedBudget,
                    advancedPayments: selectedUser.advancedPayments,
                });
                setMinQuantityValue(selectedUser.minQuantityValue)
            }
            setIsModalOpen(true);
        }
    };

    const handleSave = (updatedPayoutDetails: PayoutDetails, minQuantityValue: number) => {
        const row = rows.find(
            (row) => row.itemId === editRow?.itemId && row.userId === editRow?.userId
        );

        updateUserDetails(
            false,
            updatedPayoutDetails.target,
            getPayoutType(updatedPayoutDetails),
            updatedPayoutDetails.payoutType,
            row.itemId,
            row.userId,
            updatedPayoutDetails.isOpenEnded,
            updatedPayoutDetails.threshold,
            updatedPayoutDetails.cap,
            minQuantityValue,
            updatedPayoutDetails.advancedBudget,
            updatedPayoutDetails.advancedPayments,

        );
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!selectedIncentive) {
            setRows([]);
        }
    }, [selectedIncentive, setRows]);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.itemsContainer}>
                <ButtonGroup className={classes.buttonsContainer}>
                    <Button
                        color="primary"
                        onClick={() => setShowIncentives(false)}
                        variant={!showIncentives ? "contained" : "outlined"}
                        size="small"
                        className={classes.widthButton}
                    >
                        Objectives
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => setShowIncentives(true)}
                        variant={showIncentives ? "contained" : "outlined"}
                        size="small"
                        className={classes.widthButton}
                    >
                        Incentives
                    </Button>
                </ButtonGroup>
            <TextField
                label="Search by title"
                size="small"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.searchField}
            />
                <div className={classes.itemsList}>
                    {loading ? (
                        <div className={classes.circularProgress}>
                            <CircularProgress />
                        </div>
                    ) : incentives.length === 0 ? (
                        <Typography
                            className={classes.notFound}
                            variant="body1"
                        >
                            No grouped Incentives found
                        </Typography>
                    ) : (
                        incentives.map((item) => (
                            <ItemCard
                                key={item._id}
                                item={item}
                                handleItemSelect={handleItemSelect}
                                selectedItems={selectedIncentive ? [selectedIncentive] : []}
                            />
                        ))
                    )}
                </div>
            {!loading && (
                <div className={classes.pagination}>
                    <Pagination
                        size="small"
                        color="primary"
                        count={totalPages}
                            page={reportingGroupedPage}
                            onChange={(_, value) => setReportingGroupedPage(value)}
                    />
                </div>
            )}
        </div >
            <GroupedReportTable
                rows={rows}
                itemType="incentive"
                loading={innerLoading}
                onEdit={handleEditClick}
            />
            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                fullWidth
                disableEnforceFocus
                PaperProps={{
                    style: {
                        position: "absolute",
                        right: 0,
                        minWidth: "50vw",
                        maxHeight: "100vh",
                        height: "100%",
                        margin: 0,
                        borderRadius: 0,
                    },
                }}
            >
                <EditUserTargetPayout
                    username={username}
                    initialPayoutDetails={payoutDetails}
                    onSave={handleSave}
                    editRow={editRow}
                    initialMinQuantityValue={minQuantityValue}
                />
            </Dialog>
        </div >
    )
}
