import React from 'react';
import useAccountTypes from '../../../../hooks/useAccountTypes';
import { FormControl, IconButton } from '@material-ui/core';
import Select from 'react-select';
import { AddCircleOutline } from "@material-ui/icons";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CustomObjIncAccountFilters } from '../../../../types/commonObjIncTypes';
import { useStyles } from './AccountsSelection.styles';

type CustomAccountsSelectionProps = {
	selectedAccountsFilters: CustomObjIncAccountFilters;
	setSelectedAccountsFilters: React.Dispatch<React.SetStateAction<CustomObjIncAccountFilters>>;
};

export const CustomAccountsSelection: React.FC<
	CustomAccountsSelectionProps
> = ({ selectedAccountsFilters, setSelectedAccountsFilters }) => {
	const classes = useStyles();
	const { accountTypes, chains, locations } = useAccountTypes();

	const premises = ['On Premise', 'Off Premise'];

	const handleFilterChange = (filterType: string, values: string[]) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: values,
		}));
	};

	const handleSelectAll = (filterType: string, options: string[]) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: options,
		}));
	};

	const handleRemoveAll = (filterType: string) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: [],
		}));
	};

	return (
		<div className={classes.container}>
			{[
				{ filterName: 'accountType', label: 'Account Types', options: accountTypes },
				{ filterName: 'premise', label: 'Premise Types', options: premises },
				{ filterName: 'chain', label: 'Chains', options: chains },
				{ filterName: 'location', label: 'Locations', options: locations },
			].map(({ filterName, label, options }) => (
				<FormControl key={filterName} variant="outlined" className={classes.marginTop}>
					<div className={classes.rowContainer}>
						<Select
							className={classes.select}
							isMulti
							name={filterName}
							placeholder={`Select ${label}`}
							options={options.map((type) => ({
								value: type,
								label: type,
							}))}
							value={selectedAccountsFilters[filterName as keyof CustomObjIncAccountFilters]?.map((type) => ({
								value: type,
								label: type,
							}))}

							onChange={(multi) =>
								handleFilterChange(filterName, multi.map((type: any) => type.value))
							}
						/>
						<div className={classes.buttonContainer}>
							<IconButton
								color="primary"
								onClick={() => handleSelectAll(filterName, options)}
								aria-label="Select All"
								size="small"
							>
								<AddCircleOutline />
							</IconButton>
							<IconButton
								onClick={() => handleRemoveAll(filterName)}
								aria-label="Remove All"
								size="small"
							>
								<HighlightOffIcon />
							</IconButton>
						</div>
					</div>
				</FormControl>
			))}
		</div>
	);
};
