import { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { objectivesStoreZustand } from "../store/objectivesStore";
import { mapRowToColumns } from "../utils/mapRowToColumns";
import { ButtonGroup, CircularProgress } from "@material-ui/core";
import { ExportXLSButton } from "../utils/ExportXLSXButton";
import { ExportCSVButton } from "../utils/ExportCSVButton";
import SearchField from "../utils/Search";
import ToggleColumnsButton from "../utils/ToggleColumnsButton";
import {
	determineColumnsToShowIncentive,
	determineColumnsToShowObjective,
	transformColumnName,
} from "./utils";
import TableContainer from "@material-ui/core/TableContainer";
import { Item } from "../../usersManagement/types/commonObjIncTypes";
import { incentivesStoreZustand } from "../store/incentivesStore";
import { useStyles } from "./ItemManagementReportTable.styles";
import React from "react";

export interface IRow {
	userName?: string;
	userType?: string;
	objective?: string;
	subObjectives?: string;
	incentive?: string;
	subIncentives?: string;
	startDate?: string;
	endDate?: string;
	type?: string;
	route?: string;
	itemId: string;
	userId: string;
	isOpenEnded: boolean;
}

interface IProps {
	itemType: string;
	loading: boolean;
	columns: string[];
	rows: IRow[];
	selectedItem?: Item[];
	shouldResetFilters: boolean;
	setShouldResetFilters: (value: boolean) => void;
	onEdit: (row: any) => void;
}

export default function ItemsManagementReportTable(props: IProps) {
	const {
		itemType,
		loading,
		columns,
		rows,
		shouldResetFilters,
		setShouldResetFilters,
		onEdit,
	} = props;

	const store =
		itemType === "objective"
			? objectivesStoreZustand()
			: incentivesStoreZustand();

	const {
		sortColumnName,
		sortDirection,
		setDirection,
		setColumnName,
		setOrder,
	} = store;

	const classes = useStyles();

	const calculateTotalPayout = (rows: any[]): number => {
		let totalPayout = 0;
		rows.forEach((row) => {
			totalPayout += parseFloat(row.payout || 0);
		});
		return totalPayout;
	};

	const calculateTotalTarget = (rows: any[]): number => {
		let totalTarget = 0;
		rows.forEach((row) => {
			totalTarget += parseFloat(row.target || 0);
		});
		return totalTarget;
	};
	// assuming PayoutMTD is a number
	const calculateTotalPayoutMTD = (rows: any[]): number => {
		let totalPayoutMTD = 0;
		rows.forEach((row) => {
			totalPayoutMTD += parseFloat(row.payoutMTD || 0);
		});
		return totalPayoutMTD;
	};

	const calculateTotalQuantity = (rows: any[]): number => {
		let totalQuantity = 0;
		rows.forEach((row) => {
			totalQuantity += parseInt(row.totalItems || 0);
		})
		return totalQuantity;
	}

	const [searchValue, setSearchValue] = useState<string>("");

	const handleSearchChange = (value: string) => {
		setSearchValue(value.toLowerCase());
	};

	const filteredRows = rows.filter((row) => {
		return (
			row.userName?.toLowerCase().includes(searchValue) ||
			row.userType?.toLowerCase().includes(searchValue) ||
			row.objective?.toLowerCase().includes(searchValue) ||
			(typeof row.subObjectives === "string" &&
				row.subObjectives.toLowerCase().includes(searchValue)) ||
			(row.startDate &&
				new Date(row.startDate)
					.toLocaleDateString()
					.toLowerCase()
					.includes(searchValue)) ||
			(row.endDate &&
				new Date(row.endDate)
					.toLocaleDateString()
					.toLowerCase()
					.includes(searchValue)) ||
			row.type?.toLowerCase().includes(searchValue) ||
			row.route?.toLowerCase().includes(searchValue)
		);
	});

	const determineColumnsToShow =
		itemType === "objective"
			? determineColumnsToShowObjective(rows)
			: determineColumnsToShowIncentive(rows);

	const [showAllColumns, setShowAllColumns] = useState(false);

	const columnsToShow = showAllColumns ? columns : determineColumnsToShow;

	useEffect(() => {
		if (shouldResetFilters) {
			setSearchValue("");
			setShowAllColumns(false);
			setShouldResetFilters(false);
		}
	}, [shouldResetFilters, setShouldResetFilters]);

	return (
		<div className={classes.overflowHidden}>
			<ButtonGroup className={classes.buttonGroupContainer}>
				<SearchField
					label="Search in report table"
					value={searchValue}
					onChange={handleSearchChange}
				/>
				<ToggleColumnsButton
					showAllColumns={showAllColumns}
					setShowAllColumns={setShowAllColumns}
				/>
				<ExportXLSButton
					columns={columns}
					rows={rows}
					calculateTotalPayout={calculateTotalPayout}
					calculateTotalPayoutMTD={calculateTotalPayoutMTD}
					calculateTotalTarget={calculateTotalTarget}
				/>
				<ExportCSVButton
					columns={columns}
					rows={rows}
					calculateTotalPayout={calculateTotalPayout}
					calculateTotalPayoutMTD={calculateTotalPayoutMTD}
					calculateTotalTarget={calculateTotalTarget}
				/>
			</ButtonGroup>
			{!loading && (
				<TableContainer className={classes.tableContainerHeight}>
					<Table stickyHeader size="medium">
						<TableHead>
							<TableRow>
								{columnsToShow.map((column, index) => (
									<TableCell className={`${classes.headerCell}`} key={index}>
										<TableSortLabel
											active={sortColumnName === column}
											direction={sortDirection}
											onClick={() => {
												const transformedColName = transformColumnName(column);
												setDirection(sortDirection === "asc" ? "desc" : "asc");
												setColumnName(column);
												setOrder(
													sortDirection === "asc" ? "desc" : "asc",
													transformedColName
												);
											}}
										>
											{column}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredRows.length !== 0 &&
								filteredRows.map((row, index) => {
									const orderedRow = mapRowToColumns(
										row,
										columnsToShow,
										classes,
										onEdit
									);
									return (
										<TableRow key={index} className={classes.clickableRow}>
											{orderedRow.map((cellContent, cellIndex) => (
												<React.Fragment key={cellIndex}>
													{cellContent}
												</React.Fragment>
											))}
										</TableRow>
									);
								})}
							{rows.length !== 0 && (
								<TableRow className={classes.totalRow}>
									<TableCell>Total</TableCell>
									{columnsToShow.slice(1).map((column, index) => (
										<TableCell key={index}>
											{column === "Quantity"
												? isNaN(calculateTotalQuantity(filteredRows))
													? ""
													: calculateTotalQuantity(filteredRows)
												: ""}
											{column === "Target"
												? isNaN(calculateTotalTarget(filteredRows))
													? "∞"
													: `${calculateTotalTarget(filteredRows)}`
												: ""}
											{column === "Payout MTD"
												? isNaN(calculateTotalPayoutMTD(filteredRows))
													? "∞"
													: `$ ${calculateTotalPayoutMTD(filteredRows)}`
												: ""}
											{column === "Potential"
												? isNaN(calculateTotalPayout(filteredRows))
													? "∞"
													: `$ ${calculateTotalPayout(filteredRows)}`
												: ""}
										</TableCell>
									))}
								</TableRow>
							)}
						</TableBody>
					</Table>
					{rows.length === 0 && (
						<div className={classes.emptyBox}>
							<Typography variant="subtitle1">No data</Typography>
						</div>
					)}
				</TableContainer>
			)}
			{loading && (
				<div className={classes.loadingFlex}>
					<CircularProgress
						size={"sm"}
						className={classes.loading}
					/>
				</div>
			)}
		</div>
	);
}
