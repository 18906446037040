import {
	CustomObjIncAccountFilters,
	CustomObjIncProductFilters,
	MeasureSymbol,
	ObjIncProducts,
	ObjIncentiveImages,
} from "./commonObjIncTypes";

export type ObjectiveUser = {
	user: string;
	isTeamLead?: boolean;
	images: ObjIncentiveImages | [];
	quantity: number | null;
	validation: boolean | null;
	completed: boolean;
	goal: number | null;
	totalPods?: number;
	payout: number;
	target: number;
	isOpenEnded: boolean;
	payoutType: string;
	threshold: { units: string; value: number };
	cap: { units: string; value: number };
	progress?: number;
	totalAchieved?: number;
	percentageAchieved?: number;
	totalItems?: number;
	totalPayout?: number;
	isEdited: boolean;
	minQuantityValue?: number;
	advancedBudget?: number;
	advancedPayments?: AdvancedPayment[];
	_id?: string;
};

export interface MeasureState {
	label?: string;
	startDate?: string;
	endDate?: string;
	checked?: boolean;
	symbol?: MeasureSymbol;
	trackingOption?: string;
	trackingPeriod?: number;
	trackingPeriodStart?: string;
	trackingPeriodEnd?: string;
	referenceTrackingPeriod?: string;
}

export type AdvancedPayment = {
	completed: number;
	ofBudget: number;
};

export type Objective = {
	_id: string;
	name: string;
	type: string;
	description?: string;
	period?: string;
	customPeriodStart?: string;
	customPeriodEnd?: string;
	payout: number;
	status?: number;
	buyerIds?: string[];
	productsFilters?: CustomObjIncProductFilters;
	products?: ObjIncProducts;
	accountsFilters?: CustomObjIncAccountFilters;
	selectAllAccounts?: boolean;
	users?: ObjectiveUser[];
	favorite?: boolean;
	archived?: boolean;
	customId?: string;
	price?: number;
	earned?: number;
	progress?: number;
	available?: string;
	quantity?: {
		achieved: number;
		target: number;
	};
	advancedPayments?: AdvancedPayment[];
	isSubObjective?: boolean;
	parentObjectiveId?: string;
	measure?: MeasureState;
	target: number;
	isOpenEnded: boolean;
	threshold?: { units: string; value: number };
	cap?: { units: string; value: number };
	payoutType?: string;
	quantityPayout?: number;
	images?: ObjIncentiveImages;
	hasCheck?: boolean;
	multi?: boolean;
	subObjectives?: Objective[];
	expanded: boolean;
	isGrouped?: boolean;
	groupedObjectives?: any[];
	weight?: number;
	valueInputType?: string;
	valueInput?: number;
	isValid: boolean;
	minQuantityValue?: number;
	advancedBudget?: number;
	hideFromSalesReps?: boolean;
	customProductsSelected: boolean;
	customAccountsSelected: boolean;
};

export enum ObjectiveTypeEnum {
	Image = "image", // done
	Binary = "binary", // done
	Validation = "validation",
	Quantity = "quantity",
	Multi = "multi",
	Grouped = "grouped",
	ImageValidation = "imageValidation",
}

export const objectiveTypes: { value: ObjectiveTypeEnum; label: string }[] = [
	{ value: ObjectiveTypeEnum.Image, label: "Image Objective" },
	{ value: ObjectiveTypeEnum.Binary, label: "Binary Objective" },
	{ value: ObjectiveTypeEnum.Quantity, label: "Quantity Objective" },
	{ value: ObjectiveTypeEnum.Multi, label: "Multi Objective" },
	{ value: ObjectiveTypeEnum.Grouped, label: "Grouped Objective" },
	{ value: ObjectiveTypeEnum.Validation, label: "Validation Objective" },
	{
		value: ObjectiveTypeEnum.ImageValidation,
		label: "Image Validation Objective",
	},
];

export const subObjectiveTypes: { value: ObjectiveTypeEnum; label: string }[] =
	[
		{ value: ObjectiveTypeEnum.Image, label: "Image Objective" },
		{ value: ObjectiveTypeEnum.Binary, label: "Binary Objective" },
		{ value: ObjectiveTypeEnum.Quantity, label: "Quantity Objective" },
		{
			value: ObjectiveTypeEnum.ImageValidation,
			label: "Image Validation Objective",
		},
	];
